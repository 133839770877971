import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, User, Calendar } from 'lucide-react';
import { formatDate } from '../../utils/blogHelpers';
import { motion } from 'framer-motion';
import OptimizedImage from '../OptimizedImage';

const BlogCard = ({ blog }) => {
    return (
        <motion.article
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
        >
            <Link to={`/blog/${blog.slug}`} className="block h-full">
                <div className="relative h-48">
                    <OptimizedImage
                        src={blog.mainImage}
                        alt={blog.titleGe}
                        className="w-full h-full"
                    />
                </div>

                <div className="p-6">
                    <h2 className="text-xl font-semibold text-gray-900 mb-3 line-clamp-2 hover:text-indigo-600 transition-colors duration-200">
                        {blog.titleGe}
                    </h2>

                    <div className="flex flex-wrap items-center text-sm text-gray-500 gap-4">
                        <div className="flex items-center">
                            <User className="w-4 h-4 mr-2" />
                            <span>{blog.authorGe}</span>
                        </div>

                        <div className="flex items-center">
                            <Calendar className="w-4 h-4 mr-2" />
                            <span>{formatDate(blog.date)}</span>
                        </div>

                        <div className="flex items-center">
                            <Clock className="w-4 h-4 mr-2" />
                            <span>{blog.readingTime} წთ</span>
                        </div>
                    </div>
                </div>
            </Link>
        </motion.article>
    );
};

export default BlogCard;