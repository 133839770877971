// Base metadata for the site
export const baseMetadata = {
    siteName: 'NotaryTranslation',
    siteUrl: 'https://notarytranslation.ge',
    phone: '+995 579 737 737',
    email: 'notarytranslation24@gmail.com',
    address: {
        street: 'Chubinashvili St. 50',
        city: 'Tbilisi',
        country: 'Georgia'
    }
};

// Generate basic meta tags
export const generateBasicMeta = (title, description) => {
    return {
        title,
        description,
        keywords: [
            // Georgian
            'ნოტარიული თარგმნა',
            'ნოტარიული თარგმნა დამოწმებით',
            'თარგმანი დამოწმებით',
            'სასწრაფო თარგმანი',
            'თარგმნა ნოტარიულად',
            'თარჯიმანი ნოტარიუსთან',
            // Russian
            'нотариальный перевод',
            'нотариальный перевод с заверением',
            'срочный перевод',
            'перевод в тот же день',
            // English
            'notary translation',
            'certified translation',
            'same day translation',
            'legal document translation'
        ].join(', ')
    };
};

// Generate schema markup for the organization
export const generateOrganizationSchema = () => {
    return {
        '@context': 'https://schema.org',
        '@type': 'LocalBusiness',
        name: 'NotaryTranslation',
        image: `${baseMetadata.siteUrl}/assets/images/logo.png`,
        '@id': baseMetadata.siteUrl,
        url: baseMetadata.siteUrl,
        telephone: baseMetadata.phone,
        email: baseMetadata.email,
        address: {
            '@type': 'PostalAddress',
            streetAddress: baseMetadata.address.street,
            addressLocality: baseMetadata.address.city,
            addressCountry: baseMetadata.address.country
        },
        geo: {
            '@type': 'GeoCoordinates',
            latitude: '41.7151',
            longitude: '44.7951'
        },
        openingHoursSpecification: {
            '@type': 'OpeningHoursSpecification',
            dayOfWeek: [
                'Monday', 'Tuesday', 'Wednesday', 'Thursday',
                'Friday', 'Saturday', 'Sunday'
            ],
            opens: '00:00',
            closes: '24:00'
        },
        sameAs: [
            'https://www.facebook.com/notarytranslation',
            'https://www.instagram.com/notarytranslation'
        ],
        priceRange: '₾₾',
        description: {
            'ka': 'პროფესიონალური ნოტარიული თარგმანის სერვისი თბილისში. სასწრაფო თარგმანი იმავე დღეს.',
            'ru': 'Профессиональный нотариальный перевод в Тбилиси. Срочный перевод в тот же день.',
            'en': 'Professional notary translation service in Tbilisi. Same day translation available.'
        }
    };
};

// Generate schema markup for blog posts
export const generateBlogPostSchema = (post) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        headline: post.titleGe,
        image: `${baseMetadata.siteUrl}${post.mainImage}`,
        datePublished: post.date,
        dateModified: post.date,
        author: {
            '@type': 'Person',
            name: post.authorGe
        },
        publisher: {
            '@type': 'Organization',
            name: 'NotaryTranslation',
            logo: {
                '@type': 'ImageObject',
                url: `${baseMetadata.siteUrl}/assets/images/logo.png`
            }
        },
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': `${baseMetadata.siteUrl}/blog/${post.slug}`
        },
        inLanguage: 'ka',
        timeRequired: `PT${post.readingTime}M`
    };
};

// Generate breadcrumb schema
export const generateBreadcrumbSchema = (items) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items.map((item, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: item.name,
            item: `${baseMetadata.siteUrl}${item.path}`
        }))
    };
};