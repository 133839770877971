import React, { useState, useEffect } from 'react';

const AdvertBanner = () => {
  const [isLoaded, setIsLoaded] = useState(false);

  const scrollToCalculator = () => {
    const calculator = document.getElementById('calculator');
    if (calculator) {
      calculator.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div className="w-full bg-gray-100 py-4 mt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          onClick={scrollToCalculator}
          className="w-full focus:outline-none"
          aria-label="Advertisement Banner - Click to scroll to calculator"
        >
          <div
            className={`w-full overflow-hidden transition-opacity duration-500 ${isLoaded ? 'opacity-100' : 'opacity-0'
              }`}
          >
            {/* Desktop Banner */}
            <div className="hidden md:block">
              <img
                src="/assets/images/common/banner.jpg"
                alt="Special Offer Banner"
                className="w-full h-96 object-cover rounded-lg shadow-lg"
                width={1248}
                height={384}
                loading="lazy"
              />
            </div>

            {/* Mobile Banner */}
            <div className="md:hidden">
              <img
                src="/assets/images/common/banner.jpg"
                alt="Special Offer Banner"
                className="w-full h-32 object-cover rounded-lg shadow-lg"
                width={416}
                height={128}
                loading="lazy"
              />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AdvertBanner;