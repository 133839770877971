// Track user interactions
export const trackUserInteraction = (eventName, category, label) => {
    if (window.gtag) {
        window.gtag('event', eventName, {
            'event_category': category,
            'event_label': label
        });
    }
};

// Common tracking events
export const trackPhoneClick = () => {
    trackUserInteraction('phone_click', 'Contact', 'Phone Call');
};

export const trackEmailClick = () => {
    trackUserInteraction('email_click', 'Contact', 'Email');
};

export const trackWhatsAppClick = () => {
    trackUserInteraction('whatsapp_click', 'Contact', 'WhatsApp');
};

// Track blog interactions
export const trackBlogRead = (blogTitle) => {
    trackUserInteraction('blog_read', 'Blog', blogTitle);
};

// Track scroll depth
export const initScrollTracking = () => {
    let markers = [25, 50, 75, 90];
    let markersSeen = new Set();

    const calculateScrollDepth = () => {
        const scrollPercent = 
            (window.scrollY + window.innerHeight) / 
            document.documentElement.scrollHeight * 100;

        markers.forEach(marker => {
            if (scrollPercent >= marker && !markersSeen.has(marker)) {
                markersSeen.add(marker);
                trackUserInteraction('scroll_depth', 'Engagement', `${marker}%`);
            }
        });
    };

    window.addEventListener('scroll', calculateScrollDepth, { passive: true });
};

// Track time on page
export const initTimeTracking = () => {
    const intervals = [30, 60, 120, 300]; // seconds
    intervals.forEach(interval => {
        setTimeout(() => {
            trackUserInteraction('time_on_page', 'Engagement', `${interval}s`);
        }, interval * 1000);
    });
};