import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, Globe, Phone } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';
import { copyToClipboard } from '../utils/helpers';

const Header = ({ language, setLanguage }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const t = language === 'english' ? english : georgian;

    useEffect(() => {
        const checkIfMobile = () => {
            setIsMobile(window.innerWidth < 768);
        };
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);
        return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const scrollToSection = (sectionId) => {
        console.log('Attempting to scroll to section:', sectionId);
        
        // Add a small delay to ensure DOM is ready
        setTimeout(() => {
            const element = document.getElementById(sectionId);
            console.log('Found element:', element);
            
            if (element) {
                // Get the header element to calculate its actual height
                const header = document.querySelector('header');
                const headerHeight = header ? header.offsetHeight : 80;
                console.log('Header height:', headerHeight);

                // Calculate position
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
                
                console.log('Scrolling to position:', offsetPosition);

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }, 100);
    };

    const handlePhoneClick = () => {
        if (isMobile) {
            window.location.href = 'tel:579737737';
        } else {
            copyToClipboard(
                '579737737',
                language === 'georgian' ? 'ნომერი დაკოპირებულია' : 'Number copied'
            );
        }
    };

    const navigationItems = [
        { key: 'home', path: '/', section: 'main' },
        { key: 'about', path: '/#about', section: 'about' },
        { key: 'prices', path: '/#prices', section: 'prices' },
        { key: 'testimonials', path: '/#testimonials', section: 'testimonials' },
        { key: 'blog', path: '/blog', section: null }
    ];

    const handleNavClick = async (e, item) => {
        e.preventDefault();
        console.log('Nav item clicked:', item);
        
        // Close mobile menu first
        setIsMenuOpen(false);

        // If it's the blog link, navigate and return
        if (item.key === 'blog') {
            navigate('/blog');
            return;
        }

        // If we're not on the home page, navigate to home first
        if (location.pathname !== '/') {
            navigate('/');
            // Wait for navigation to complete
            setTimeout(() => {
                scrollToSection(item.section);
            }, 100);
            return;
        }

        // If we're already on home page, just scroll
        scrollToSection(item.section);
    };

    const handleLanguageToggle = () => {
        setLanguage(language === 'english' ? 'georgian' : 'english');
    };

    // Force scroll on mount if there's a hash in the URL
    useEffect(() => {
        if (location.hash) {
            const sectionId = location.hash.slice(1); // Remove the # from the hash
            setTimeout(() => {
                scrollToSection(sectionId);
            }, 500); // Longer delay on initial load
        }
    }, [location]);

    return (
        <div className="fixed w-full top-11 md:top-9 z-40">
            <header className="bg-white shadow-sm">
                <div className="max-w-7xl mx-auto">
                    <div className="flex items-center justify-between px-4 py-3 sm:px-6 lg:px-8">
                        {/* Logo */}
                        <div className="flex-shrink-0">
                            <Link 
                                to="/" 
                                className="flex items-center text-indigo-600"
                                onClick={() => setIsMenuOpen(false)}
                            >
                                <Globe className="h-6 w-6 mr-2" />
                                <span className="text-xl font-bold hidden sm:inline">NotaryTranslation</span>
                            </Link>
                        </div>

                        {/* Desktop Navigation */}
                        <nav className="hidden md:flex items-center space-x-8">
                            {navigationItems.map((item) => (
                                <button // Changed to button for better mobile handling
                                    key={item.key}
                                    onClick={(e) => handleNavClick(e, item)}
                                    className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
                                >
                                    {t[item.key]}
                                </button>
                            ))}
                        </nav>

                        {/* Right side buttons */}
                        <div className="flex items-center space-x-4">
                            {/* Phone number */}
                            <button
                                onClick={handlePhoneClick}
                                className="hidden md:flex items-center px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition duration-200"
                            >
                                <Phone className="h-5 w-5 mr-2" />
                                <span>579 737 737</span>
                            </button>

                            {/* Language switcher */}
                            <button
                                onClick={handleLanguageToggle}
                                className="px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 transition duration-200"
                            >
                                {language === 'english' ? 'ქართ' : 'ENG'}
                            </button>

                            {/* Mobile menu button */}
                            <button
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                                className="md:hidden p-2 rounded-md text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                aria-label="Toggle menu"
                            >
                                {isMenuOpen ? (
                                    <X className="h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <Menu className="h-6 w-6" aria-hidden="true" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                {/* Mobile menu */}
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="md:hidden bg-white border-t border-gray-200"
                        >
                            <div className="px-4 pt-2 pb-3 space-y-1">
                                {navigationItems.map((item) => (
                                    <button // Changed to button for better mobile handling
                                        key={item.key}
                                        onClick={(e) => handleNavClick(e, item)}
                                        className="block w-full text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                    >
                                        {t[item.key]}
                                    </button>
                                ))}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </header>

            {/* Mobile phone banner */}
            {isMobile && (
                <div className="bg-indigo-600 text-white text-center py-2">
                    <a href="tel:579737737" className="flex items-center justify-center">
                        <Phone className="h-5 w-5 mr-2" />
                        <span>579 737 737</span>
                    </a>
                </div>
            )}

            {/* Spacer for fixed header */}
            <div className="h-16 md:h-20" />
        </div>
    );
};

export default Header;