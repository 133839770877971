import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, Globe, Award, ArrowRight } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';

const Prices = ({ language }) => {
    const t = language === 'english' ? english : georgian;

    return (
        <motion.section
            id="prices"
            className="mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
        >
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">{t.pricesTitle}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{t.pricingPolicy}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{t.pricingDetails}</p>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <DollarSign className="mr-2" /> {t.standardPrice}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">15 ₾ / {language === 'georgian' ? 'გვერდი' : 'page'}</dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Globe className="mr-2" /> {t.rareLanguages}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{language === 'georgian' ? 'დაახლოებით 22.5 ₾ / გვერდი' : 'Approximately 22.5 ₾ / page'}</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Award className="mr-2" /> {t.notaryApprovalPrices}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul className="list-disc pl-5">
                                    <li>{language === 'georgian' ? '1 გვერდი: 6 ₾' : '1 page: 6 ₾'}</li>
                                    <li>{language === 'georgian' ? '2-10 გვერდი: 4 ₾ თითო გვერდზე' : '2-10 pages: 4 ₾ per page'}</li>
                                    <li>{language === 'georgian' ? '11-50 გვერდი: 3 ₾ თითო გვერდზე' : '11-50 pages: 3 ₾ per page'}</li>
                                    <li>{language === 'georgian' ? '51+ გვერდი: 2 ₾ თითო გვერდზე' : '51+ pages: 2 ₾ per page'}</li>
                                </ul>
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <ArrowRight className="mr-2" /> {t.discounts}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {language === 'georgian'
                                    ? '10% ფასდაკლება 50+ გვერდზე, 15% ფასდაკლება 100+ გვერდზე'
                                    : '10% discount for 50+ pages, 15% discount for 100+ pages'}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </motion.section>
    );
};

export default Prices;