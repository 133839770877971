import React from 'react';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';

const Testimonials = ({ language }) => {
    const t = language === 'english' ? english : georgian;
    
    const testimonials = [
        { id: 1, name: language === 'georgian' ? "გიორგი მ." : "George M.", comment: language === 'georgian' ? "ძალიან კმაყოფილი ვარ მიღებული მომსახურებით. სწრაფი და ხარისხიანი თარგმანი მივიღე." : "I'm very satisfied with the service. I received a quick and high-quality translation.", rating: 5 },
        { id: 2, name: language === 'georgian' ? "ნინო გ." : "Nino G.", comment: language === 'georgian' ? "პროფესიონალი გუნდი და შესანიშნავი მომსახურება. აუცილებლად ვისარგებლებ კვლავ." : "Professional team and excellent service. I will definitely use their services again.", rating: 5 },
        { id: 3, name: language === 'georgian' ? "ლევან კ." : "Levan K.", comment: language === 'georgian' ? "სანოტარო დამოწმების სერვისმა ბევრი დრო დამიზოგა. მადლობა!" : "The notary approval service saved me a lot of time. Thank you!", rating: 5 },
        { id: 4, name: language === 'georgian' ? "თამარ ბ." : "Tamar B.", comment: language === 'georgian' ? "ზუსტი და დროული თარგმანი. ძალიან კმაყოფილი ვარ." : "Accurate and timely translation. I'm very satisfied.", rating: 4 },
    ];

    return (
        <motion.section
            id="testimonials"
            className="mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
        >
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">{t.testimonialsTitle}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{t.happyClients}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{t.clientOpinions}</p>
                </div>
                <div className="border-t border-gray-200">
                    {testimonials.map((review) => (
                        <div key={review.id} className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">{review.name}</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <p>{review.comment}</p>
                                <div className="flex mt-2">
                                    {[...Array(5)].map((_, i) => (
                                        <Star key={i} className={`h-5 w-5 ${i < review.rating ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" />
                                    ))}
                                </div>
                            </dd>
                        </div>
                    ))}
                </div>
            </div>
        </motion.section>
    );
};

export default Testimonials;