import React, { useState, useEffect } from 'react';
import { Loader } from 'lucide-react';

const OptimizedImage = ({
  src,
  alt,
  className = '',
  sizes = '(min-width: 1280px) 384px, (min-width: 1040px) 33vw, (min-width: 780px) 50vw, 100vw'
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  // Generate responsive sources
  const generateSrcSet = (src) => {
    const widths = [384, 512, 768, 1024];
    return widths
      .map(width => `${src}?w=${width} ${width}w`)
      .join(', ');
  };

  // Generate placeholder
  const placeholderSrc = `${src}?w=50&blur=true`;

  return (
    <div className={`relative overflow-hidden ${className}`}>
      {/* Placeholder/Loading State */}
      {isLoading && (
        <div className="absolute inset-0 bg-gray-100 animate-pulse flex items-center justify-center">
          <Loader className="w-6 h-6 text-gray-400 animate-spin" />
        </div>
      )}

      {/* Main Image */}
      <img
        src={src}
        srcSet={generateSrcSet(src)}
        sizes={sizes}
        alt={alt}
        loading="lazy"
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
          setError(true);
        }}
        className={`w-full h-full object-cover transition-opacity duration-300 ${
          isLoading ? 'opacity-0' : 'opacity-100'
        }`}
      />

      {/* Fallback for errors */}
      {error && (
        <div className="absolute inset-0 bg-gray-100 flex items-center justify-center">
          <span className="text-gray-500 text-sm">Failed to load image</span>
        </div>
      )}
    </div>
  );
};

export default OptimizedImage;