import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Clock, User, Calendar } from 'lucide-react';
import { getAllBlogs, formatDate } from '../../utils/blogHelpers';
import { getOptimizedImageUrl, handleImageError } from '../../utils/imageUtils';
import BlogCard from './BlogCard';  // Added this import

const BlogList = () => {
    const blogs = getAllBlogs();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>ბლოგი - NotaryTranslation</title>
                <meta name="description" content="წაიკითხეთ სასარგებლო სტატიები თარგმანის და ნოტარიული მომსახურების შესახებ" />
                <meta name="keywords" content="თარგმანი, ნოტარიული თარგმანი, სერტიფიცირებული თარგმანი, ბლოგი" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className="pt-24 pb-12">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    {/* Page Header */}
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            ბლოგი
                        </h1>
                        <p className="text-lg text-gray-600">
                            გაეცანით სიახლეებს თარგმანისა და ნოტარიული მომსახურების შესახებ
                        </p>
                    </div>

                    {/* Blog Grid */}
                    <motion.div
                        className="grid gap-8 md:grid-cols-2 lg:grid-cols-3"
                        variants={containerVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        {blogs.map((blog) => (
                            <BlogCard key={blog.id} blog={blog} />
                        ))}
                    </motion.div>

                    {/* No Blogs Message */}
                    {blogs.length === 0 && (
                        <div className="text-center py-12">
                            <p className="text-gray-600 text-lg">
                                ბლოგის პოსტები არ მოიძებნა
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default BlogList;