import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Calculator, X, Clock, Mail, Phone } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';
import {
    languageTranslations,
    languages,
    languagePrices,
    calculateNotaryPrice,
    calculateDeliveryTime,
    copyToClipboard
} from '../utils/helpers';
import { trackCalculatePrice } from '../utils/analytics';
import { trackCustomEvent } from '../utils/metaPixel';

const PriceCalculator = ({ language }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [fromLang, setFromLang] = useState('english');
    const [toLang, setToLang] = useState('georgian');
    const [pageCount, setPageCount] = useState(1);
    const [notaryApproval, setNotaryApproval] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [translationPrice, setTranslationPrice] = useState(0);
    const [notaryPrice, setNotaryPrice] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);
    const [deliveryTime, setDeliveryTime] = useState('');

    const t = language === 'english' ? english : georgian;

    const calculatePrice = (e) => {
        e.preventDefault();

        trackCalculatePrice();

        // Track Facebook Pixel event
        trackCustomEvent('CalculatePrice', {
            fromLanguage: fromLang,
            toLanguage: toLang,
            pageCount: pageCount,
            notaryApproval: notaryApproval,
            currency: 'GEL'
        });

        let basePrice = languagePrices[fromLang];
        let translationCost = basePrice * pageCount;
        let discount = 0;

        if (pageCount >= 100) {
            discount = translationCost * 0.15;
        } else if (pageCount >= 50) {
            discount = translationCost * 0.10;
        }

        translationCost -= discount;

        let notaryCost = 0;
        if (notaryApproval) {
            notaryCost = calculateNotaryPrice(pageCount);
        }

        const total = translationCost + notaryCost;

        setTranslationPrice(translationCost);
        setNotaryPrice(notaryCost);
        setDiscountAmount(discount);
        setTotalPrice(total);
        setDeliveryTime(calculateDeliveryTime(pageCount, notaryApproval, language));
        setIsOpen(true);
    };

    const copyEmail = () => {
        navigator.clipboard.writeText('notarytranslation24@gmail.com').then(
            () => {
                alert(language === 'georgian' ? 'იმეილი წარმატებით დაკოპირდა' : 'Email successfully copied');
                // Track email copy event
                trackCustomEvent('CopyEmail');
            },
            (err) => console.error('Error copying: ', err)
        );
    };

    const copyPhoneNumber = () => {
        navigator.clipboard.writeText('579737737').then(
            () => {
                alert(language === 'georgian' ? 'ტელეფონის ნომერი წარმატებით დაკოპირდა' : 'Phone number successfully copied');
                // Track phone copy event
                trackCustomEvent('CopyPhone');
            },
            (err) => console.error('Error copying: ', err)
        );
    };

    const handleLanguageChange = (e, type) => {
        const value = e.target.value;
        if (type === 'from') {
            setFromLang(value);
            // Track language selection event
            trackCustomEvent('SelectLanguage', {
                type: 'source',
                language: value
            });
        } else {
            setToLang(value);
            // Track language selection event
            trackCustomEvent('SelectLanguage', {
                type: 'target',
                language: value
            });
        }
    };


    return (
        <>
            <form onSubmit={calculatePrice} className="space-y-6">
                <div>
                    <label htmlFor="fromLang" className="form-label">
                        {t.sourceLanguage}
                    </label>
                    <select
                        id="fromLang"
                        value={fromLang}
                        onChange={(e) => handleLanguageChange(e, 'from')}
                        className="select-field"
                    >
                        {languages.map((lang) => (
                            <option key={lang} value={lang}>
                                {languageTranslations[language][lang]}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="toLang" className="form-label">
                        {t.targetLanguage}
                    </label>
                    <select
                        id="toLang"
                        value={toLang}
                        onChange={(e) => handleLanguageChange(e, 'to')}
                        className="select-field"
                    >
                        {languages.map((lang) => (
                            <option key={lang} value={lang}>
                                {languageTranslations[language][lang]}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label htmlFor="pageCount" className="form-label">
                        {t.pageCount}
                    </label>
                    <input
                        type="number"
                        id="pageCount"
                        value={pageCount}
                        onChange={(e) => {
                            setPageCount(parseInt(e.target.value));
                            // Track page count change
                            trackCustomEvent('UpdatePageCount', {
                                count: parseInt(e.target.value)
                            });
                        }}
                        min="1"
                        className="input-field"
                    />
                </div>

                <div className="flex items-center">
                    <input
                        id="notaryApproval"
                        type="checkbox"
                        checked={notaryApproval}
                        onChange={(e) => {
                            setNotaryApproval(e.target.checked);
                            // Track notary approval toggle
                            trackCustomEvent('ToggleNotaryApproval', {
                                enabled: e.target.checked
                            });
                        }}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label htmlFor="notaryApproval" className="ml-2 block text-sm text-gray-900">
                        {t.notaryApproval}
                    </label>
                </div>

                <button type="submit" className="btn-primary w-full">
                    <Calculator className="mr-2" />
                    {t.calculate}
                </button>
            </form>

            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="calculator-modal"
                    >
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            transition={{ type: "spring", damping: 15, stiffness: 100 }}
                            className="calculator-modal-content"
                        >
                            <div className="flex justify-between items-center mb-6">
                                <h3 className="text-2xl font-semibold text-gray-900">
                                    {t.translationDetails}
                                </h3>
                                <button
                                    onClick={() => setIsOpen(false)}
                                    className="text-gray-400 hover:text-gray-500 transition-common"
                                >
                                    <X className="h-6 w-6" />
                                </button>
                            </div>

                            <div className="space-y-4">
                                <p className="text-sm text-gray-500">
                                    {languageTranslations[language][fromLang]} - {languageTranslations[language][toLang]}
                                </p>

                                <p className="text-sm text-gray-500">
                                    {t.pageCount}: {pageCount}
                                </p>

                                <p className="text-sm text-gray-500">
                                    {t.pricePerPage}: {languagePrices[fromLang]} ₾
                                </p>

                                {discountAmount > 0 && (
                                    <p className="discount-text">
                                        {t.discount}: {discountAmount.toFixed(2)} ₾
                                    </p>
                                )}

                                <p className="text-sm text-gray-700">
                                    {t.translationCost}: {translationPrice.toFixed(2)} ₾
                                </p>

                                {notaryApproval && (
                                    <p className="text-sm text-gray-700">
                                        {t.notaryCost}: {notaryPrice.toFixed(2)} ₾
                                    </p>
                                )}

                                <p className="price-display">
                                    {t.totalPrice}: {totalPrice.toFixed(2)} ₾
                                </p>

                                <hr className="border-t border-gray-200 my-4" />

                                <div className="delivery-time">
                                    <Clock className="mr-2" />
                                    {t.estimatedDeliveryTime}: {deliveryTime}
                                </div>
                            </div>

                            <div className="mt-8 space-y-4">
                                <button
                                    onClick={copyEmail}
                                    className="btn-primary w-full"
                                >
                                    <Mail className="mr-2" />
                                    {t.copyEmail}
                                </button>

                                <button
                                    onClick={copyPhoneNumber}
                                    className="btn-secondary w-full"
                                >
                                    <Phone className="mr-2" />
                                    {t.copyPhone}
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default PriceCalculator;