// src/utils/metaPixel.js

export const FB_PIXEL_ID = '572460575438133';

// Initialize Meta Pixel
export const initializeMetaPixel = () => {
    if (typeof window !== 'undefined') {
        // Disable eslint for the next line as it's Facebook's standard initialization code
        // eslint-disable-next-line no-unused-expressions
        (function(f,b,e,v,n,t,s) {
            if(f.fbq) return;
            n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];
            t=b.createElement(e);t.async=!0;t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s);
        })(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js'
        );
        
        window.fbq('init', FB_PIXEL_ID);
        window.fbq('track', 'PageView');
    }
};

// Track page views
export const trackPageView = () => {
    if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'PageView');
    }
};

// Custom event tracking
export const trackCustomEvent = (eventName, eventData = null) => {
    if (typeof window !== 'undefined' && window.fbq) {
        if (eventData) {
            window.fbq('track', eventName, eventData);
        } else {
            window.fbq('track', eventName);
        }
    }
};