import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from './components/Header';
import PriceCalculator from './components/PriceCalculator';
import About from './components/About';
import Prices from './components/Prices';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import BlogList from './components/Blog/BlogList';
import BlogPost from './components/Blog/BlogPost';
import { english } from './translations/english';
import { georgian } from './translations/georgian';
import { trackLanguageChange } from './utils/analytics';
import { usePageTracking } from './utils/analytics';
import { initializeMetaPixel, trackPageView } from './utils/metaPixel';
import TawkTo from './components/TawkTo';
import AnnouncementBar from './components/AnnouncementBar';
import AdvertBanner from './components/AdvertBanner';

const MainContent = ({ language, t }) => (
    <main className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="px-4 py-6 sm:px-0">
            {/* Main Section */}
            <motion.section
                id="main"
                className="mb-12 bg-white bg-opacity-90 p-8 rounded-lg shadow-md mt-20"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
            >
                <h2 className="text-3xl font-extrabold text-gray-900 mb-6">
                    {t.mainTitle}
                </h2>
                <p className="mb-6 text-xl text-gray-700">
                    {t.emailInstruction} <strong>notarytranslation24@gmail.com</strong>{' '}
                    {t.receiveTranslation} <strong>90</strong> {t.minutes}
                </p>

                {/* Calculator Section */}
                <div id="calculator" className="bg-white p-6 rounded-lg shadow-sm">
                    <PriceCalculator language={language} />
                </div>
            </motion.section>

            {/* Other Sections */}
            <About language={language} />
            <Prices language={language} />
            <Testimonials language={language} />
        </div>
    </main>
);

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        trackPageView();
    }, [location]);

    return null;
};


const App = () => {
    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('preferredLanguage');
        return savedLanguage || 'english';
    });

    useEffect(() => {
        // Initialize Meta Pixel
        initializeMetaPixel();
    }, []);

    usePageTracking();

    const handleLanguageChange = (newLanguage) => {
        setLanguage(newLanguage);
        localStorage.setItem('preferredLanguage', newLanguage);
        trackLanguageChange(newLanguage);
    };

    const t = language === 'english' ? english : georgian;

    return (
        <div>
            <AnnouncementBar />
            <Router>
                <RouteChangeTracker />
                <div className="min-h-screen flex flex-col pt-11 md:pt-9">
                    <Header language={language} setLanguage={handleLanguageChange} />
                    <div className="flex-grow bg-gray-50">
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <>
                                        <AdvertBanner />  {/* Add it here */}
                                        <MainContent language={language} t={t} />
                                    </>
                                }
                            />
                            <Route
                                path="/blog"
                                element={<BlogList />}
                            />
                            <Route
                                path="/blog/:slug"
                                element={<BlogPost />}
                            />
                        </Routes>
                    </div>
                    <Footer language={language} />
                </div>
            </Router>
        </div>
    );
};

export default App;
