import { useEffect } from 'react';

// Google Analytics event tracking
export const trackEvent = (eventName, category, label) => {
    if (window.gtag) {
        window.gtag('event', eventName, {
            'send_to': 'G-C8VB6K0FD1',
            'event_category': category,
            'event_label': label
        });
    }
};

// Common tracking events
export const trackLanguageChange = (language) => {
    trackEvent('language_change', 'User Preference', language);
};

export const trackCalculatePrice = () => {
    trackEvent('calculate_price_click', 'User Interaction', 'Calculate Price');
};

// Custom hook for page tracking
export const usePageTracking = () => {
    useEffect(() => {
        if (window.gtag) {
            window.gtag('send', 'pageview');
        }
    }, []);
};