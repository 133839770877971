import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Users, Languages, Briefcase } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';

const About = ({ language }) => {
    const t = language === 'english' ? english : georgian;

    return (
        <motion.section
            id="about"
            className="mb-12"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
        >
            <h2 className="text-3xl font-extrabold text-gray-900 mb-6">{t.aboutTitle}</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{t.companyInfo}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">{t.detailedInfo}</p>
                </div>
                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Calendar className="mr-2" /> {t.foundationYear}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">2023</dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Users className="mr-2" /> {t.translatorCount}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">50+</dd>
                        </div>
                        <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Languages className="mr-2" /> {t.languages}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {language === 'georgian' ? 
                                    'ინგლისური, ქართული, რუსული, გერმანული, ფრანგული, და სხვა' : 
                                    'English, Georgian, Russian, German, French, and others'}
                            </dd>
                        </div>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                <Briefcase className="mr-2" /> {t.specialization}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{t.specializationDetails}</dd>
                        </div>
                    </dl>
                </div>
            </div>
        </motion.section>
    );
};

export default About;