import React from 'react';

const AnnouncementBar = ({ text = "სპეციალური შემოთავაზება: 20% ფასდაკლება 100+ გვერდის თარგმანზე" }) => {
  return (
    <div className="w-full bg-indigo-600 fixed top-0 left-0 z-50">
      <div className="max-w-7xl mx-auto">
        <div className="h-11 md:h-9 px-4 flex items-center justify-center">
          <p className="text-white text-sm md:text-base font-medium truncate">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementBar;