export const english = {
    // Header
    home: "Home",
    about: "About Us",
    prices: "Prices",
    testimonials: "Testimonials",

    // Main section
    mainTitle: "Professional Translation Services",
    emailInstruction: "Send us your document to",
    receiveTranslation: "and receive the translation in",
    minutes: "minutes",

    // Price Calculator
    sourceLanguage: "Source Language",
    targetLanguage: "Target Language",
    pageCount: "Number of Pages",
    notaryApproval: "Notary Approval",
    calculate: "Calculate",
    translationDetails: "Translation Details",
    pricePerPage: "Price per Page",
    discount: "Discount",
    translationCost: "Translation Cost",
    notaryCost: "Notary Cost",
    totalPrice: "Total Price",
    estimatedDeliveryTime: "Estimated Delivery Time",
    copyEmail: "Copy Email",
    copyPhone: "Copy Phone Number",

    // About section
    aboutTitle: "About Us",
    companyInfo: "Company Information",
    detailedInfo: "Detailed information about our services",
    foundationYear: "Foundation Year",
    translatorCount: "Number of Translators",
    languages: "Languages",
    specialization: "Specialization",
    specializationDetails: "Legal, Technical, Medical, and Literary translations",

    // Prices section
    pricesTitle: "Prices and Policies",
    pricingPolicy: "Pricing Policy",
    pricingDetails: "Detailed information about our pricing",
    standardPrice: "Standard Price",
    rareLanguages: "Rare Languages",
    notaryApprovalPrices: "Notary Approval",
    discounts: "Discounts",

    // Testimonials section
    testimonialsTitle: "Client Testimonials",
    happyClients: "Testimonials from Satisfied Clients",
    clientOpinions: "What our clients say about us",

    // Footer
    company: "Company",
    contact: "Contact",
    services: "Services",
    documentTranslation: "Document Translation",
    notaryService: "Notary Service",
    expressTranslation: "Express Translation",
    getInTouch: "Get in Touch",
    allRightsReserved: "All rights reserved",

    //Blog
    blog: "Blog",
    readMore: "Read More",
    readingTime: "min read",
};