import React, { useEffect } from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Clock, User, Calendar, ArrowLeft, Phone, Mail, MessageCircle } from 'lucide-react';

import {
    getBlogBySlug,
    formatDate,
    getAllBlogs
} from '../../utils/blogHelpers';
import {
    generateBasicMeta,
    generateBlogPostSchema,
    generateBreadcrumbSchema
} from '../../utils/seoUtils';
import {
    trackBlogRead,
    trackPhoneClick,
    trackEmailClick,
    trackWhatsAppClick,
    initScrollTracking,
    initTimeTracking
} from '../../utils/trackingUtils';

const BlogPost = () => {
    const { slug } = useParams();
    const blog = getBlogBySlug(slug);
    const allBlogs = getAllBlogs();

    useEffect(() => {
        if (blog) {
            // Initialize tracking
            initScrollTracking();
            initTimeTracking();
            trackBlogRead(blog.titleGe);

            // Scroll to top
            window.scrollTo(0, 0);
        }
    }, [blog]);

    if (!blog) {
        return <Navigate to="/blog" replace />;
    }

    const breadcrumbItems = [
        { name: 'მთავარი', path: '/' },
        { name: 'ბლოგი', path: '/blog' },
        { name: blog.titleGe, path: `/blog/${blog.slug}` }
    ];

    // Get related blogs (excluding current blog)
    const relatedBlogs = allBlogs
        .filter(b => b.id !== blog.id)
        .slice(0, 3);

    return (
        <>
            <Helmet>
                <title>{`${blog.titleGe} - NotaryTranslation | ნოტარიული თარგმნა`}</title>
                <meta name="description" content={`${blog.titleGe}. პროფესიონალური ნოტარიული თარგმანის სერვისი. სასწრაფო თარგმანი იმავე დღეს.`} />
                <meta name="keywords" content={generateBasicMeta().keywords} />

                {/* Open Graph */}
                <meta property="og:title" content={blog.titleGe} />
                <meta property="og:description" content={`${blog.titleGe}. პროფესიონალური ნოტარიული თარგმანის სერვისი.`} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:image" content={`${window.location.origin}${blog.mainImage}`} />

                {/* Article specific meta tags */}
                <meta property="article:published_time" content={blog.date} />
                <meta property="article:author" content={blog.authorGe} />
                <meta property="article:section" content="Translation Services" />

                {/* Schema.org markup */}
                <script type="application/ld+json">
                    {JSON.stringify(generateBlogPostSchema(blog))}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify(generateBreadcrumbSchema(breadcrumbItems))}
                </script>
            </Helmet>

            <article className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
                {/* Breadcrumbs */}
                <nav className="text-sm mb-8">
                    <ol className="list-none p-0 inline-flex">
                        {breadcrumbItems.map((item, index) => (
                            <li key={item.path} className="flex items-center">
                                {index > 0 && <span className="mx-2 text-gray-500">/</span>}
                                <Link
                                    to={item.path}
                                    className="text-indigo-600 hover:text-indigo-800"
                                >
                                    {item.name}
                                </Link>
                            </li>
                        ))}
                    </ol>
                </nav>

                {/* Hero Image */}
                <img
                    src={blog.mainImage}
                    alt={blog.titleGe}
                    className="w-full h-64 object-cover rounded-lg shadow-lg mb-8"
                />

                {/* Article Header */}
                <header className="mb-8">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        {blog.titleGe}
                    </h1>

                    <div className="flex flex-wrap items-center text-gray-600 gap-4">
                        <div className="flex items-center">
                            <User className="w-5 h-5 mr-2" />
                            <span>{blog.authorGe}</span>
                        </div>

                        <div className="flex items-center">
                            <Calendar className="w-5 h-5 mr-2" />
                            <span>{formatDate(blog.date)}</span>
                        </div>

                        <div className="flex items-center">
                            <Clock className="w-5 h-5 mr-2" />
                            <span>{blog.readingTime} წთ</span>
                        </div>
                    </div>
                </header>

                {/* Article Content */}
                <div
                    className="prose prose-lg max-w-none mb-12"
                    dangerouslySetInnerHTML={{ __html: blog.content.ge }}
                />

                {/* Contact Section */}
                {/* Contact Section */}
                <div className="bg-gray-50 rounded-lg p-6 mb-12">
                    <h2 className="text-2xl font-bold text-gray-900 mb-6">
                        დაგვიკავშირდით თარგმანისთვის
                    </h2>
                    <div className="flex flex-col gap-4">
                        <a
                            href="tel:579737737"
                            onClick={() => trackPhoneClick()}
                            className="inline-flex items-center justify-center px-6 py-3 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors"
                        >
                            <Phone className="w-5 h-5 mr-3" />
                            579 737 737
                        </a>

                        <a
                            href="mailto:notarytranslation24@gmail.com"
                            onClick={() => trackEmailClick()}
                            className="inline-flex items-center justify-center px-6 py-3 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                        >
                            <Mail className="w-5 h-5 mr-3" />
                            მოგვწერეთ
                        </a>

                        <a
                            href="https://wa.me/995579737737"
                            onClick={() => trackWhatsAppClick()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="inline-flex items-center justify-center px-6 py-3 bg-[#25D366] text-white rounded-md hover:bg-green-500 transition-colors"
                        >
                            <MessageCircle className="w-5 h-5 mr-3" />
                            WhatsApp
                        </a>
                    </div>
                </div>

                {/* Related Posts */}
                {relatedBlogs.length > 0 && (
                    <section className="border-t pt-12">
                        <h2 className="text-2xl font-bold text-gray-900 mb-6">
                            მსგავსი სტატიები
                        </h2>
                        <div className="grid gap-8 md:grid-cols-3">
                            {relatedBlogs.map(relatedBlog => (
                                <Link
                                    key={relatedBlog.id}
                                    to={`/blog/${relatedBlog.slug}`}
                                    className="block group"
                                >
                                    <img
                                        src={relatedBlog.mainImage}
                                        alt={relatedBlog.titleGe}
                                        className="w-full h-48 object-cover rounded-lg mb-4"
                                    />
                                    <h3 className="text-lg font-semibold text-gray-900 group-hover:text-indigo-600">
                                        {relatedBlog.titleGe}
                                    </h3>
                                </Link>
                            ))}
                        </div>
                    </section>
                )}
            </article>
        </>
    );
};

export default BlogPost;