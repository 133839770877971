import blogData from '../data/blogs.json';

// Get all blogs sorted by date (newest first)
export const getAllBlogs = () => {
    return blogData.blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
};

// Get blog by slug
export const getBlogBySlug = (slug) => {
    return blogData.blogs.find(blog => blog.slug === slug);
};

// Format date for display
export const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('ka-GE', options);
};

// Generate JSON-LD schema for blog post
export const generateBlogSchema = (blog) => {
    return {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": blog.titleGe,
        "datePublished": blog.date,
        "author": {
            "@type": "Person",
            "name": blog.authorGe
        },
        "image": `${window.location.origin}${blog.mainImage}`,
        "publisher": {
            "@type": "Organization",
            "name": "NotaryTranslation",
            "logo": {
                "@type": "ImageObject",
                "url": `${window.location.origin}/logo.png`
            }
        }
    };
};

// Generate meta tags for blog post
export const generateMetaTags = (blog) => {
    return {
        title: blog.titleGe,
        description: `${blog.titleGe} - ავტორი: ${blog.authorGe}`,
        image: blog.mainImage,
        url: `${window.location.origin}/blog/${blog.slug}`
    };
};