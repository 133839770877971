export const georgian = {
    // Header
    home: "მთავარი",
    about: "ჩვენ შესახებ",
    prices: "ფასები",
    testimonials: "გამოხმაურებები",

    // Main section
    mainTitle: "ნოტარიული თარგმანი დამოწმებით",
    emailInstruction: "დაგვიკავშირდით",
    receiveTranslation: "და მიიღეთ თარგმანი",
    minutes: "წუთში",

    // Price Calculator
    sourceLanguage: "საწყისი ენა",
    targetLanguage: "სამიზნე ენა",
    pageCount: "გვერდების რაოდენობა",
    notaryApproval: "სანოტარო დამოწმება",
    calculate: "გამოთვლა",
    translationDetails: "თარგმანის დეტალები",
    pricePerPage: "ფასი ერთ გვერდზე",
    discount: "ფასდაკლება",
    translationCost: "თარგმანის ღირებულება",
    notaryCost: "სანოტარო დამოწმება",
    totalPrice: "ჯამური ფასი",
    estimatedDeliveryTime: "მიახლოებითი მიწოდების დრო",
    copyEmail: "ელ-ფოსტის კოპირება",
    copyPhone: "ტელეფონის ნომრის კოპირება",

    // About section
    aboutTitle: "ჩვენს შესახებ",
    companyInfo: "კომპანიის შესახებ",
    detailedInfo: "დეტალური ინფორმაცია ჩვენი სერვისების შესახებ",
    foundationYear: "დაარსების წელი",
    translatorCount: "თარჯიმნების რაოდენობა",
    languages: "ენები",
    specialization: "სპეციალიზაცია",
    specializationDetails: "იურიდიული, ტექნიკური, სამედიცინო, და ლიტერატურული თარგმანები",

    // Prices section
    pricesTitle: "ფასები და პოლიტიკა",
    pricingPolicy: "ფასების პოლიტიკა",
    pricingDetails: "დეტალური ინფორმაცია ჩვენი ფასების შესახებ",
    standardPrice: "სტანდარტული ფასი",
    rareLanguages: "იშვიათი ენები",
    notaryApprovalPrices: "სანოტარო დამოწმება",
    discounts: "ფასდაკლებები",

    // Testimonials section
    testimonialsTitle: "კლიენტების გამოხმაურებები",
    happyClients: "კმაყოფილი კლიენტების გამოხმაურებები",
    clientOpinions: "ჩვენი კლიენტების აზრი ჩვენს შესახებ",

    // Footer
    company: "კომპანია",
    contact: "კონტაქტი",
    services: "სერვისები",
    documentTranslation: "დოკუმენტების თარგმნა",
    notaryService: "სანოტარო დამოწმება",
    expressTranslation: "ექსპრეს თარგმანი",
    getInTouch: "დაგვიკავშირდით",
    allRightsReserved: "ყველა უფლება დაცულია",

    //Blog
    blog: "ბლოგი",
    readMore: "სრულად ნახვა",
    readingTime: "წთ საკითხავი",
};