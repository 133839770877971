import React from 'react';
import { Link } from 'react-router-dom';
import { Phone, Mail, MapPin, Globe } from 'lucide-react';
import { english } from '../translations/english';
import { georgian } from '../translations/georgian';

const Footer = ({ language }) => {
    const t = language === 'english' ? english : georgian;

    const handleEmailClick = () => {
        navigator.clipboard.writeText('notarytranslation24@gmail.com')
            .then(() => {
                alert(language === 'georgian' ? 
                    'ელ-ფოსტა დაკოპირებულია' : 
                    'Email copied to clipboard'
                );
            });
    };

    const companyLinks = [
        { key: 'about', path: '/#about' },
        { key: 'prices', path: '/#prices' },
        { key: 'blog', path: '/blog' }
    ];

    const services = [
        'documentTranslation',
        'notaryService',
        'expressTranslation'
    ];

    return (
        <footer className="bg-gray-900">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {/* Main footer content */}
                <div className="py-12">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        {/* Logo and company description */}
                        <div className="col-span-1">
                            <Link to="/" className="flex items-center text-white mb-4">
                                <Globe className="h-8 w-8 mr-2" />
                                <span className="text-xl font-bold">NotaryTranslation</span>
                            </Link>
                            <p className="text-gray-400 text-sm">
                                {language === 'georgian' 
                                    ? 'პროფესიონალური თარგმანის სერვისი'
                                    : 'Professional Translation Services'
                                }
                            </p>
                        </div>

                        {/* Company links */}
                        <div>
                            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">
                                {t.company}
                            </h3>
                            <ul className="space-y-3">
                                {companyLinks.map(link => (
                                    <li key={link.key}>
                                        <Link
                                            to={link.path}
                                            className="text-gray-300 hover:text-white transition duration-150 ease-in-out"
                                        >
                                            {t[link.key]}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Services */}
                        <div>
                            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">
                                {t.services}
                            </h3>
                            <ul className="space-y-3">
                                {services.map(service => (
                                    <li key={service}>
                                        <span className="text-gray-300">
                                            {t[service]}
                                        </span>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {/* Contact information */}
                        <div>
                            <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">
                                {t.getInTouch}
                            </h3>
                            <ul className="space-y-3">
                                <li>
                                    <a
                                        href="tel:+995579737737"
                                        className="flex items-center text-gray-300 hover:text-white transition duration-150 ease-in-out"
                                    >
                                        <Phone className="h-5 w-5 mr-3" />
                                        +995 579 737 737
                                    </a>
                                </li>
                                <li>
                                    <button
                                        onClick={handleEmailClick}
                                        className="flex items-center text-gray-300 hover:text-white transition duration-150 ease-in-out"
                                    >
                                        <Mail className="h-5 w-5 mr-3" />
                                        notarytranslation24@gmail.com
                                    </button>
                                </li>
                                <li>
                                    <a
                                        href="https://maps.app.goo.gl/VJ6hqwzq2NhoFH3v6"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center text-gray-300 hover:text-white transition duration-150 ease-in-out"
                                    >
                                        <MapPin className="h-5 w-5 mr-3" />
                                        {language === 'georgian' 
                                            ? 'თბილისი, ჩუბინაშვილის 50'
                                            : 'Tbilisi, 50 Chubinashvili St.'
                                        }
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="border-t border-gray-800 py-6">
                    <p className="text-center text-sm text-gray-400">
                        © {new Date().getFullYear()} NotaryTranslation. {t.allRightsReserved}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;